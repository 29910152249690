<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">
      Staff
    </div>
    <BCol class="p-0 d-flex">
      <b-dropdown
        id="dropdown-1"
        class="filter"
        no-caret
        variant="primary"
        toggle-class="text-decoration-none toggle-custom button-primary rounded-lg py-0 px-1 mr-1"
      >
        <h5>Filter</h5>
        <template #button-content>
          <b-img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
            class="height-[20px]"
          />
        </template>
        <b-dropdown
          id="dropdown-2"
          class="w-100"
          toggle-class="text-left"
          variant="none"
          left
          no-caret
          dropright
        >
          <template #button-content>
            <span class="hover-salmon text-6 w-100">Divisi</span>
          </template>
          <b-dropdown-form style="width: 200px">
            <b-form-checkbox
              v-for="(division, index) in listDivisi"
              :key="index"
              v-model="filterSelected"
              :name="`checkbox-${index}`"
              class="text-6 mb-1"
              :value="division.id"
              @change="getListData()"
            >
              {{ division.division_name }}
            </b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
        <h5
          class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer"
          @click="resetFilter()"
        >
          Reset filter
        </h5>
      </b-dropdown>
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama"
          class="border-0"
          @input="handleSearch()"
        />
      </div>
      <BButton
        tag="router-link"
        to="/staff/create"
        variant="primary"
        class="ml-auto px-3"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 275px); overflow-y: scroll;"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            <div class="flex items-center">
              <img
                v-if="data.item.photo_profile_url === ''"
                src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
                alt="Komerce"
                class="w-[40px] h-[40px] rounded-circle"
              >
              <img
                v-else
                :src="data.item.photo_profile_url"
                onerror="this.onerror=null;this.src='https://storage.googleapis.com/komerce/assets/avatars/image-null.png';"
                alt="Komerce"
                class="w-[40px] h-[40px] rounded-circle object-fit-cover"
              >
              <div class="ml-1">
                {{ data.item.name }}
              </div>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  name: $route.meta.routeEdit,
                  params: { id: data.item.id }
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                  class="mr-[5px]"
                >
              </BButton>
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
              >
                <img
                  v-b-modal.modalDelete
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                  alt="Komerce"
                  @click="selectItem(data.item.id)"
                >
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>

    <ModalDelete
      :id-item="idItem"
      @deleted="getListData()"
    />
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { tableBiodata } from './config'
import ModalDelete from './ModalDelete.vue'

export default {
  components: {
    ModalDelete,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableBiodata,
      idItem: 0,
      filterSelected: [],
      listDivisi: [],
      alertError,
    }
  },
  computed: {
    divisi() {
      return this.filterSelected.join(',')
    },
  },
  created() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  mounted() {
    this.getListData()
    this.getListDivisi()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&division=${this.divisi}`
      const url = `v1/staffs?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getListDivisi() {
      const params = 'status=true'
      const url = `v1/divisions?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listDivisi = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&division=${this.divisi}`
        const url = `v1/staffs?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    resetFilter() {
      this.filterSelected = []
      this.getListData()
    },
  },
}
</script>
